import DownloadIcon from "@mui/icons-material/Download";
import { Button, Dialog, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getOrder,
  getOrderShipment,
  orderShipment,
  updateOrderStatus,
} from "store/slices/ordersSlice";
import { UPDATE_ORDER_STATUS_OPTIONS } from "utils/constants/updateOrderStatusOptions";
import { toast } from "utils/hooks/useToast";
import OrderTrackingDetail from "./OrderTrackingDetail/OrderTrackingDetail";

function OrderStatus() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const order = useSelector((state) => state.orders?.order);
  const loading = useSelector((state) => state.orders.orderLoading);

  const handleChange = useCallback(
    (event) => {
      if (order?.orderStatus?.name === "confirmed") {
        const shipment = {
          order: params.id,
          seller: order.seller?.id,
          shippmentType: "intercity",
          shippmentMethod: "shyp",
        };
        dispatch(orderShipment(shipment))
          .unwrap()
          .then(() => {
            dispatch(
              updateOrderStatus({ name: event.target.value, order: params.id })
            )
              .unwrap()
              .then(() => {
                toast.success("Order status changed successfully");
                dispatch(getOrder({ orderId: params?.id }));
                navigate(`/orders/${order?.id}`);
              })
              .catch(() => {
                toast.error("Failed to update order status");
              });
          });
      } else {
        dispatch(
          updateOrderStatus({ name: event.target.value, order: params.id })
        )
          .unwrap()
          .then(() => {
            toast.success("Order status changed successfully");
            navigate(`/orders/${order?.id}`);
          })
          .catch(() => {
            toast.error("Failed to update order status");
          });
      }
    },
    [dispatch, navigate, order, params.id]
  );

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleShipment = () => {
    dispatch(getOrderShipment(order?.shippment))
      .unwrap()
      .then((res) => {
        if (res?.label) {
          const link = document.createElement("a");
          link.href = res?.label;
          link.target = "_blank";
          link.download = res?.label.split("/").pop();
          link.click();
        } else {
          console.error("Shipment label URL is not available.");
        }
      });
  };

  return (
    <>
      <StyledMuiCard
        title={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <span>Order Status</span>
            <Stack direction="row" gap={1}>
              {(order?.orderStatus?.name === "ready" ||
                order?.orderStatus?.name === "completed" ||
                order?.orderStatus?.name === "delivered") && (
                // order?.shippment && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleShipment}
                  endIcon={<DownloadIcon />}
                >
                  Invoice
                </Button>
              )}
              <Button
                variant="outlined"
                color="primary"
                onClick={handleDialogOpen}
              >
                Track Order
              </Button>
            </Stack>
          </Box>
        }
        subTitle="Please select order status"
      >
        <CardContent>
          <Box
            width="100%"
            position="relative"
            display="inline-flex"
            alignItems="center"
          >
            <Select
              fullWidth
              displayEmpty
              value={order?.orderStatus?.name ?? ""}
              onChange={handleChange}
              disabled={loading}
              sx={{ minWidth: 120 }}
              renderValue={(selected) => (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  textTransform="capitalize"
                >
                  {selected || "Select an option"}
                  {loading && order ? <CircularProgress size={18} /> : null}
                </Box>
              )}
            >
              {UPDATE_ORDER_STATUS_OPTIONS.map((option) => (
                <MenuItem
                  key={option.value}
                  dense
                  value={option.value}
                  disabled={
                    !option.isEnabled.includes(order?.orderStatus?.name ?? "")
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </CardContent>
      </StyledMuiCard>
      <Dialog open={open} onClose={handleDialogClose} maxWidth="md" fullWidth>
        <OrderTrackingDetail order={order} onClose={handleDialogClose} />
      </Dialog>
    </>
  );
}

export default memo(OrderStatus);
