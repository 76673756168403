import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DateRange from "components/Pickers/DateRange";
import FilterByStore from "components/Product/FilterByStore";
import FilterByCountry from "components/UI/FilterByCountry";
import { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  FILTERS_BORDER_COLOR,
  FILTER_TEXT_COLOR,
  PRIMARY,
} from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

function Filters() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const userRole = useSelector((state) => state.auth?.userRole);

  const toggle = useCallback(() => {
    setOpen(!open);
    if (open) {
      navigate(`/orders`);
    }
  }, [navigate, open]);

  const handleChange = useCallback(
    (range) => {
      let _params = params;
      _params.from = range.startDate.toDateString();
      _params.to = range.endDate.toDateString();
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  return (
    <Box display="flex" gap={2} sx={containerStyles}>
      {!open ? (
        <Button
          variant="text"
          onClick={toggle}
          className="Filter-toggle-btn"
          sx={{
            borderBottom: "2px solid",
            borderColor: PRIMARY,
            borderRadius: 0,
            textTransform: "none",
          }}
        >
          Filter
        </Button>
      ) : (
        <>
          <DateRange onChange={handleChange} />
          {userRole === "admin" && (
            <>
              <FilterByStore isOrderPage />
              <FilterByCountry queryKey="origin" />
            </>
          )}
        </>
      )}
      {open && (
        <Button
          onClick={toggle}
          variant="text"
          className="Filter-toggle-btn"
          sx={{
            borderBottom: "2px solid #707070",
            borderRadius: 0,
            color: FILTER_TEXT_COLOR,
            textTransform: "none",
          }}
        >
          Clear All
        </Button>
      )}
    </Box>
  );
}

export default memo(Filters);

const containerStyles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: FILTERS_BORDER_COLOR,
    },
    "&:hover": {
      "& fieldset": {
        borderColor: FILTERS_BORDER_COLOR,
      },
    },
  },
  "& .MuiSelect-select": { height: "29px !important" },
  "& .Filter-toggle-btn": {
    textTransform: "none",
    fontSize: "1rem",
  },
};
