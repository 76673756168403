import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import BasicInfoSection from "components/FullOrders/OrderDetails/BasicInfoSection";
import BillingAddressSection from "components/FullOrders/OrderDetails/BillingAddressSection";
import OrderNote from "components/FullOrders/OrderDetails/OrderNote";
import PackagesSection from "components/FullOrders/OrderDetails/PackagesSection";
import ShippingAddressSection from "components/FullOrders/OrderDetails/ShippingAddressSection";
import SummarySection from "components/FullOrders/OrderDetails/SummarySection";
import { forwardRef } from "react";
import { useSelector } from "react-redux";

const OrderDetails = forwardRef((_, ref) => {
  const loading = useSelector((state) => state.fullOrders.orderLoading);
  const order = useSelector((state) => state.fullOrders.order);

  return (
    <Box ref={ref}>
      <Typography
        variant="h4"
        fontWeight="600"
        fontSize={{ md: "1rem", lg: "1.5rem" }}
        mb={2}
      >
        Order Detail
      </Typography>
      {loading ? (
        <Box
          height={200}
          my={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BasicInfoSection />
          </Grid>
          <Grid item xs={12}>
            <PackagesSection />
          </Grid>
          {order?.orderNote && (
            <Grid item xs={12}>
              <OrderNote />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Stack direction="column" gap={2}>
              <ShippingAddressSection />
              <BillingAddressSection />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <SummarySection />
          </Grid>
        </Grid>
      )}
    </Box>
  );
});

OrderDetails.displayName = "OrderDetails";

export default OrderDetails;
