import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

export const getFullOrders = createAsyncThunk(
  "fullOrders/getFullOrders",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().fullOrders;
    try {
      const response = await http.get(`/orderDetail`, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          sortBy: "-createdAt",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      if (responseData?.results) {
        responseData.results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getFullOrder = createAsyncThunk(
  "fullOrders/getFullOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.get(`/orderDetail/${data.orderId}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getDownloadInvoice = createAsyncThunk(
  "fullOrders/getDownloadInvoice",
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await http.get(`/order/invoice/${orderId}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const fullOrdersSlice = createSlice({
  name: "fullOrders",
  initialState: {
    loading: true,
    orderLoading: true,
    order: null,
    results: [],
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: null,
    orderError: null,
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.orderLoading = true;
      state.order = null;
      state.results = [];
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = null;
      state.orderError = null;
    },
    resetOrderState: (state) => {
      state.order = null;
      state.orderLoading = true;
      state.orderError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFullOrders.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getFullOrders.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.loading = false;
      })
      .addCase(getFullOrders.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getFullOrder.pending, (state) => {
        state.orderLoading = true;
        state.orderError = false;
      })
      .addCase(getFullOrder.fulfilled, (state, action) => {
        state.orderLoading = false;
        state.order = action.payload;
        state.orderError = false;
      })
      .addCase(getFullOrder.rejected, (state) => {
        state.orderLoading = false;
        state.orderError = true;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, resetOrderState } = fullOrdersSlice.actions;

// Exporting default reducer
export default fullOrdersSlice.reducer;
