const ReturnStatusOptions = [
  { label: "Requested", value: "requested" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
  { label: "Returned", value: "returned" },
  { label: "Received", value: "received" },
  { label: "Refunded", value: "refunded" },
  { label: "Replacement", value: "replacement" },
];

export default ReturnStatusOptions;

export const refundReasons = ["I have changed mind"];
export const replacementReasons = [
  "I received a damaged product",
  "I received a wrong product",
  "Product quality is not as shown in product",
];
