import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import eyeIcon from "assets/icons/eyeIcon.svg";
import WritingNote from "assets/images/WritingNote.svg";
import StyledDataGrid from "components/StyledGrid";
import dayjs from "dayjs";
import {
  lazy,
  memo,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getOrders, resetState } from "store/slices/ordersSlice";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";

const RelativeTime = lazy(() => import("components/UI/RelativeTime"));
const CurrencyFormatter = lazy(() => import("components/CurrencyFormatter"));

function OrdersDataGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQueryParams();
  const userRole = useSelector((state) => state.auth.userRole);
  const { results, loading, totalPages } = useSelector((state) => state.orders);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(getOrders(params));
    }, 250);

    return () => clearTimeout(timeoutId);
  }, [dispatch, params]);

  useEffect(() => () => dispatch(resetState()), [dispatch]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 50,
        flex: 0.4,
      },
      {
        field: "orderId",
        headerName: "OrderID",
        minWidth: 160,
        flex: 1.25,
        renderCell: ({ row }) => <Typography>{row?.orderId}</Typography>,
      },
      {
        field: "fullname",
        headerName: "Customer Name",
        minWidth: 180,
        flex: 1.25,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography sx={commonTypographyStyles}>
            {row?.customer?.fullname}
          </Typography>
        ),
      },
      ...(userRole === "admin"
        ? [
            {
              field: "seller",
              headerName: "Store Name",
              minWidth: 180,
              flex: 1.25,
              sortable: false,
              renderCell: ({ row }) => (
                <Typography sx={commonTypographyStyles}>
                  {row?.seller?.sellerDetail?.brandName}
                </Typography>
              ),
            },
          ]
        : []),
      {
        field: "city",
        headerName: "City",
        minWidth: 140,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography sx={commonTypographyStyles}>
            {row?.address?.city}
          </Typography>
        ),
      },
      {
        field: "createdAt",
        headerName: "Order Date",
        minWidth: 140,
        flex: 1,
        renderCell: ({ row }) => (
          <Typography>{dayjs(row?.createdAt).format("DD/MM/YYYY")}</Typography>
        ),
      },
      {
        field: "total",
        headerName: "Amount",
        minWidth: 140,
        flex: 1,
        renderCell: ({ row }) => (
          <Suspense>
            <CurrencyFormatter
              number={row?.total ?? 0}
              sx={commonTypographyStyles}
            />
          </Suspense>
        ),
      },
      {
        field: "name",
        headerName: "Status",
        type: "actions",
        minWidth: 140,
        flex: 1,
        headerAlign: "left",
        align: "left",
        sortable: false,
        renderCell: ({ row }) => (
          <Chip
            sx={{
              backgroundColor: statusBadges(row?.orderStatus?.name).bgColor,
              color: statusBadges(row?.orderStatus?.name).color,
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
              fontSize: "12px",
            }}
            variant="contained"
            label={row?.orderStatus?.name}
          />
        ),
      },
      ...(userRole === "admin"
        ? [
            {
              field: "orderStatuses",
              headerName: "Status Age",
              minWidth: 140,
              flex: 1,
              headerAlign: "left",
              align: "left",
              sortable: false,
              renderHeader: () => <CustomStatusAgeHeader />,
              renderCell: ({ row }) => {
                const lastOrderStatus =
                  row?.orderStatuses[row?.orderStatuses?.length - 1];
                const isNewStatus =
                  row?.orderStatus?.name?.toLowerCase() === "new";
                return (
                  <Suspense>
                    <RelativeTime
                      timestamp={lastOrderStatus?.date}
                      isNewStatus={isNewStatus}
                    />
                  </Suspense>
                );
              },
            },
          ]
        : []),
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        headerAlign: "right",
        align: "right",
        minWidth: 90,
        flex: 0.8,
        sortable: false,
        renderCell: ({ row }) => (
          <Box display="flex" sx={actionStyles}>
            {row?.orderNote && (
              <Tooltip title={row?.orderNote}>
                <IconButton variant="text" className="button-styles">
                  <img src={WritingNote} alt="edit" className="icon-size" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="View">
              <IconButton size="small" onClick={() => navigate(row.id)}>
                <img src={eyeIcon} alt="view" className="icon-size" />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [navigate, userRole]
  );

  return (
    <Suspense>
      <StyledDataGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
        checkboxSelection={false}
      />
    </Suspense>
  );
}

export default memo(OrdersDataGrid);

const CustomStatusAgeHeader = () => {
  const navigate = useNavigate();
  const params = useQueryParams();
  const { loading } = useSelector((state) => state.orders);
  const [isHidden, setIsHidden] = useState(false);

  const handleStatusAgeSorting = useCallback(() => {
    if (loading) return;
    setIsHidden(() => true);

    const updatedParams = { ...params };
    delete updatedParams.sortBy;

    if (params.sortBy?.includes("statusAge")) {
      if (params.sortBy?.includes("-")) {
        navigate({ search: createSearchParams(updatedParams).toString() });
      } else {
        updatedParams.sortBy = "-statusAge";
        navigate({ search: createSearchParams(updatedParams).toString() });
      }
    } else {
      updatedParams.sortBy = "statusAge";
      navigate({ search: createSearchParams(updatedParams).toString() });
    }
  }, [loading, navigate, params]);

  useEffect(() => {
    setIsHidden(() => false);
  }, [loading]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        // Match default MUI DataGrid header styles
        fontSize: "1.25rem",
        fontWeight: 500,
      }}
    >
      <Box
        component="span"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        Status Age
      </Box>
      {!loading && !isHidden && (
        <IconButton
          size="small"
          onClick={handleStatusAgeSorting}
          sx={{
            ...(!params?.sortBy?.includes("statusAge") && {
              color: "transparent",
            }),
            ":hover": {
              color: !params?.sortBy?.includes("statusAge")
                ? "text.disabled"
                : "inherit",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "1rem",
            },
          }}
        >
          {params?.sortBy?.includes("statusAge") &&
          params?.sortBy?.includes("-") ? (
            <ArrowDownwardIcon />
          ) : (
            <ArrowUpwardIcon />
          )}
        </IconButton>
      )}
    </Box>
  );
};

const actionStyles = {
  "& .icon-size": { height: "25px", width: "25px" },
};

const commonTypographyStyles = {
  textTransform: "capitalize",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};
