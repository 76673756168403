/**
 * Normalizes attribute names based on a predefined list of correct names.
 * @param {Array} attributes - Array of attributes with `name` and `values` properties.
 * @param {Array} attributesList - Array of correctly formatted attribute names.
 * @returns {Array} - Array of attributes with normalized names.
 */
export const normalizeAttributes = (attributes, attributesList) => {
  return attributes.map((attribute) => {
    // Find the correct name (case-insensitive match)
    const correctName = attributesList.find(
      (name) => name.toLowerCase() === attribute.name.toLowerCase()
    );
    if (!correctName) return attribute;

    // Replace with the correct name if found, otherwise keep the original
    return {
      ...attribute,
      name: correctName,
    };
  });
};

export const expandAttributeByName = (attributes, name) => {
  const attribute = attributes.find((obj) => obj.name === name);
  return attribute?.values?.length ? attribute?.values : [];
};

/**
 * Removes keys with undefined values from an object recursively,
 * and removes empty objects.
 * @param {Object} obj - The object to clean
 * @returns {Object} - The cleaned object
 */
const removeUndefinedKeys = (obj) => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  const cleanedObj = Object.entries(obj).reduce(
    (acc, [key, value]) => {
      const cleanedValue = removeUndefinedKeys(value); // Recur for nested objects

      // Check if the value is an array
      const isArray = Array.isArray(cleanedValue);

      // Only add non-empty objects and arrays
      if (
        cleanedValue !== undefined &&
        !(
          typeof cleanedValue === "object" &&
          !isArray &&
          Object.keys(cleanedValue).length === 0
        )
      ) {
        acc[key] = cleanedValue;
      }

      return acc;
    },
    Array.isArray(obj) ? [] : {}
  );

  return cleanedObj;
};

/**
 * Creates product API data from form values
 * @param {Object} values - Form updated values
 * @param {boolean} isArabic - Language flag
 */
export const createProductApiData = (values, isArabic) => {
  // Basic safety check
  if (!values) return {};

  const trim = (value) => value?.trim();

  const data = {
    // Basic Info
    productName: !isArabic ? trim(values?.name) : undefined,
    description: !isArabic ? trim(values?.description) : undefined,
    sku: trim(values?.sku),
    partnerSku: trim(values?.partnerSku),
    category: values?.category?.value,

    // Arabic translation
    ...(isArabic && {
      lang: {
        ar: {
          productName: trim(values?.name),
          description: trim(values?.description),
          shortDescription: trim(values?.shortDescription),
        },
      },
    }),

    // Product details
    origin: values?.origin,
    region: values?.region,
    active: values?.active,
    quantity: values?.quantity,
    regularPrice: values?.price,
    onSale: values?.onSale,
    salePrice: values?.salePrice,

    // Brand & Model Info
    brand: trim(values?.brand),
    model: trim(values?.model),
    modelNumber: trim(values?.modelNumber),
    shortDescription: !isArabic ? trim(values?.shortDescription) : undefined,

    // Attributes
    attributes: values?.attributes,

    // Package details
    packageInfo: {
      weight: values?.packageWeight
        ? values?.packageWeightUnit === "g"
          ? parseFloat(Number(values?.packageWeight / 1000).toFixed(2))
          : parseFloat(values?.packageWeight)
        : undefined,
      volume: {
        unit:
          values?.packageVolumeUnit === "" ? "none" : values?.packageVolumeUnit,
        length:
          values?.packageLength === "" ? undefined : values?.packageLength,
        width: values?.packageWidth === "" ? undefined : values?.packageWidth,
        height:
          values?.packageHeight === "" ? undefined : values?.packageHeight,
      },
    },

    // Warranty
    warranty: values?.warranty,
  };

  return removeUndefinedKeys(data);
};

/**
 * Get unused attribute combinations for product variants.
 * @param {Array} attributes - The list of attributes for the product, each with a name and possible values.
 * @param {Array} existingVariants - The list of existing variants with selectedAttributes property.
 * @returns {Array} An array of unused attribute combinations.
 */
export const getUnusedAttributes = (attributes, variants) => {
  // Helper function to generate all possible combinations
  const generateCombinations = (attrs) => {
    if (attrs.length === 0) return [{}];
    const [firstAttr, ...restAttrs] = attrs;
    const restCombinations = generateCombinations(restAttrs);

    return firstAttr.values.flatMap((value) =>
      restCombinations.map((comb) => ({
        ...comb,
        [firstAttr.name]: value,
      }))
    );
  };

  // Helper function to compare attributes case-insensitively
  const isSameAttributes = (attr1, attr2) =>
    attr1.length === attr2.length &&
    attr1.every((attr) =>
      attr2.some(
        (a) =>
          a.name.toLowerCase() === attr.name.toLowerCase() &&
          a.value.toLowerCase() === attr.value.toLowerCase()
      )
    );

  // Generate all possible combinations of attributes
  const allCombinations = generateCombinations(attributes);

  // Normalize existing combinations in variants
  const existingCombinations = variants.map((variant) =>
    variant.selectedAttributes.map((attr) => ({
      name: attr.name.toLowerCase(),
      value: attr.value.toLowerCase(),
    }))
  );

  // Filter unused combinations
  const unusedCombinations = allCombinations.filter((combination) => {
    const normalizedCombination = Object.entries(combination).map(
      ([name, value]) => ({
        name: name.toLowerCase(),
        value: value.toLowerCase(),
      })
    );
    return !existingCombinations.some((existing) =>
      isSameAttributes(normalizedCombination, existing)
    );
  });

  return unusedCombinations;
};

/**
 * Generates attribute combinations based on number of attributes:
 * - For single attribute: generates partial combinations
 * - For multiple attributes: generates only cartesian product
 * @param {Array} attributes - Array of attributes, each with a name and values.
 * @returns {Array} - Array of variant objects representing combinations.
 */
export const generateAttributeCombinations = (
  attributes = [],
  variants = []
) => {
  // Helper function to generate cartesian product
  function getCartesianProduct(arrays, current = [], index = 0) {
    const results = [];

    if (index === arrays.length) {
      if (current.length === arrays.length) {
        results.push([...current]);
      }
      return results;
    }

    const currentArray = arrays[index];
    for (const item of currentArray) {
      results.push(
        ...getCartesianProduct(arrays, [...current, item], index + 1)
      );
    }

    return results;
  }

  // Helper function to generate partial combinations for single attribute
  function getPartialCombinations(attributeArray) {
    return attributeArray[0].map((item) => [item]);
  }

  // Format attributes into array of arrays
  const attributeArrays = attributes.map((attr) =>
    attr.values.map((value) => ({ name: attr.name, value }))
  );

  // Generate combinations based on number of attributes
  let combinations;
  if (attributes.length === 1) {
    combinations = getPartialCombinations(attributeArrays);
  } else {
    combinations = getCartesianProduct(attributeArrays);
  }

  // Check if a combination exists in variants
  function combinationExists(combination) {
    return variants.some((variant) => {
      if (combination.length !== variant.selectedAttributes.length)
        return false;

      return combination.every((comb) =>
        variant.selectedAttributes.some(
          (attr) =>
            attr.name.toLowerCase() === comb.name.toLowerCase() &&
            attr.value.toLowerCase() === comb.value.toLowerCase()
        )
      );
    });
  }

  // Format the output
  const formattedCombinations = combinations.map((combination) => {
    const output = {};
    combination.forEach(({ name, value }) => {
      output[name] = value;
    });
    output.isDisabled = combinationExists(combination);
    return output;
  });

  // Sort the combinations
  formattedCombinations.sort((a, b) => {
    const aKeys = Object.keys(a).filter((key) => key !== "isDisabled");
    const bKeys = Object.keys(b).filter((key) => key !== "isDisabled");

    if (aKeys.length !== bKeys.length) {
      return aKeys.length - bKeys.length;
    }

    const aNames = [...aKeys].sort();
    const bNames = [...bKeys].sort();

    for (let i = 0; i < aNames.length; i++) {
      if (aNames[i] !== bNames[i]) {
        return aNames[i].localeCompare(bNames[i]);
      }
    }

    for (let i = 0; i < aNames.length; i++) {
      const nameCompare = String(a[aNames[i]]).localeCompare(
        String(b[bNames[i]])
      );
      if (nameCompare !== 0) {
        return nameCompare;
      }
    }

    return 0;
  });

  return formattedCombinations;
};
