import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  exportProductsCsv,
  resetExportCsvState,
} from "store/slices/productsSlice";
import useQueryParams from "utils/hooks/useQueryParams";

function ExportProductsCsv({ areFiltersOpen, handleFiltersOpening }) {
  const params = useQueryParams();
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(
    (state) => state.products.exportCsv
  );
  const [isApiCalled, setIsApiCalled] = useState(false);

  const isDateRangeValid = useMemo(() => {
    if (!params?.from || !params?.to) return false;

    const fromDate = new Date(params.from);
    const toDate = new Date(params.to);
    const diffDays =
      Math.abs(toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24);

    return diffDays < 15;
  }, [params]);

  const handleClick = () => {
    if (params?.from && params.to && isDateRangeValid) {
      const _params = { ...params };
      delete _params.name;
      delete _params.value;
      setIsApiCalled(() => true);
      dispatch(exportProductsCsv(_params));
    }
  };

  const handleAlertClick = () => {
    !error && !success && !areFiltersOpen && handleFiltersOpening(true);
  };

  const getAlertMessage = () => {
    if (success) return "Products CSV exported successfully.";
    if (error) return error;
    if ((params.from || params?.to || isApiCalled) && !isDateRangeValid)
      return "The selected date range exceeds 15 days. Please select a valid range.";
    return "You can generate a CSV for products by selecting a date range. The date range cannot exceed 15 days. The CSV will reflect the applied filters, except the search filter.";
  };

  useEffect(() => {
    dispatch(resetExportCsvState());
    return () => {
      setIsApiCalled(false);
    };
  }, [dispatch, params]);

  return (
    <Box display="flex" gap={2} alignItems="flex-start">
      <Button
        size="large"
        variant="contained"
        disabled={
          ((params.from || params?.to || isApiCalled) && !isDateRangeValid) ||
          !params.from ||
          !params?.to ||
          loading
        }
        sx={{
          textTransform: "none",
          minWidth: "fit-content",
          minHeight: "44px",
        }}
        onClick={handleClick}
      >
        {loading ? "Exporting CSV..." : "Export CSV"}
      </Button>
      <Alert
        severity={
          error ||
          ((params.from || params?.to || isApiCalled) && !isDateRangeValid)
            ? "error"
            : success
            ? "success"
            : "info"
        }
        onClick={handleAlertClick}
        sx={{
          ...(!error &&
            !success &&
            !areFiltersOpen &&
            params?.from &&
            params?.to && { cursor: "pointer" }),
        }}
      >
        {getAlertMessage()}
      </Alert>
    </Box>
  );
}

export default ExportProductsCsv;

ExportProductsCsv.propTypes = {
  areFiltersOpen: PropTypes.bool.isRequired,
  handleFiltersOpening: PropTypes.func.isRequired,
};
