function formatNumber(value = 0) {
  const num = Number(value ?? 0);
  const [integerPart, decimalPart] = num.toFixed(2).split(".");
  const formattedInteger = parseInt(integerPart, 10).toLocaleString();

  const trimmedDecimal = decimalPart?.replace(/0+$/, "");

  return trimmedDecimal
    ? `${formattedInteger}.${trimmedDecimal}`
    : formattedInteger;
}

export default formatNumber;
