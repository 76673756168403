import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PaidIcon from "@mui/icons-material/Paid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import formatCurrency from "utils/helpers/formatCurrency";

function ReportsCards() {
  const stats = useSelector((state) => state.reports.stats);

  return (
    <Grid container spacing={2}>
      <Grid item md={4}>
        <Card
          sx={{
            p: "1.5rem",
          }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <PaidIcon color="primary" fontSize="large" />
            <Typography>Total Paid Revenue</Typography>
            <Typography variant="h5" fontWeight="bold">
              {formatCurrency(stats.baseTotal ?? 0)}
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card
          sx={{
            p: "1.5rem",
          }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <MoneyOffIcon color="primary" fontSize="large" />
            <Typography>Total Unpaid</Typography>
            <Typography variant="h5" fontWeight="bold">
              {formatCurrency(stats.unPaid ?? 0)}
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card
          sx={{
            p: "1.5rem",
          }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <DoubleArrowIcon color="primary" fontSize="large" />
            <Typography>Next Payment</Typography>
            <Typography variant="h5" fontWeight="bold">
              {stats.lastPayment
                ? dayjs(stats.lastPayment).add(14, "day").format("YYYY-MM-DD")
                : "_____ __ __"}
            </Typography>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ReportsCards;
