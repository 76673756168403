import LocalMallIcon from "@mui/icons-material/LocalMall";
import { Box, Card, CardContent, Typography } from "@mui/material";
import PropTypes from "prop-types";
import formatNumber from "utils/helpers/formatNumber";

const OrderStatusCard = ({ title, percentValue, countValue }) => {
  return (
    <Card
      sx={{
        borderRadius: 2,
        boxShadow: 3,
        padding: 2,
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Box>
            <Typography variant="h6">{title ?? "--"}</Typography>
            <Box display="flex" gap={4} mt={2}>
              {percentValue !== null && (
                <Box>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    fontWeight="bold"
                  >
                    {formatNumber(percentValue ?? 0)}%
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    This month
                  </Typography>
                </Box>
              )}
              <Box>
                <Typography variant="h4" color="textPrimary" fontWeight="bold">
                  {formatNumber(countValue ?? 0)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Count
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="#eafafd"
            borderRadius="50%"
            minWidth="105px"
            height="100%"
            width="auto"
            sx={{
              aspectRatio: "1/1",
              "& .MuiSvgIcon-root": {
                fontSize: "3rem",
                color: "primary.main",
              },
            }}
          >
            <LocalMallIcon />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OrderStatusCard;

OrderStatusCard.propTypes = {
  title: PropTypes.string.isRequired,
  countValue: PropTypes.string.isRequired,
  percentValue: PropTypes.string.isRequired,
};
