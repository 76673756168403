import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDownloadInvoice } from "store/slices/fullOrdersSlice";
import { CARD_HEADER_BACKGROUND } from "styles/colors";

function PackagesSection() {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.fullOrders.order);

  const handleShipment = (id) => {
    dispatch(getDownloadInvoice(id))
      .unwrap()
      .then((res) => {
        if (res?.invoiceUrl) {
          const link = document.createElement("a");
          link.href = res?.invoiceUrl;
          link.target = "_blank";
          link.download = res?.invoiceUrl.split("/").pop();
          link.click();
        } else {
          console.error("Shipment label URL is not available.");
        }
      });
  };

  return (
    <Stack direction="column" gap={2}>
      {order?.orders?.map((_package, i) => (
        <Card key={i}>
          <CardContent
            sx={{
              p: 0,
              "&:last-Child": {
                p: 0,
              },
            }}
          >
            <Box
              bgcolor={CARD_HEADER_BACKGROUND}
              p={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  {`Package ${i + 1}`}
                </Typography>
                <Typography
                  variant="body1"
                  display="flex"
                  flexDirection="row"
                  gap={1}
                >
                  {`Sold by: `}
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="primary"
                    textTransform="capitalize"
                  >
                    {_package?.seller?.fullname}
                  </Typography>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={3}>
                <Button
                  variant="contained"
                  onClick={() => handleShipment(_package.id)}
                >
                  Download Invoice
                </Button>

                <Typography
                  variant="body1"
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  {_package?.orderStatus?.name}
                </Typography>
              </Box>
            </Box>

            <Box>
              {_package?.orderItems?.map((item) => (
                <Stack direction="row" key={item.id} mb={1} p={2} gap={2}>
                  <img
                    src={item?.product?.mainImage}
                    alt={item?.product?.productName}
                    height="50px"
                    width="50px"
                  />
                  <Box flexGrow={1}>
                    <Typography
                      variant="body1"
                      component={"a"}
                      target="_blank"
                      href={`${process.env.REACT_APP_PLATFORM_DOMAIN}/product/${item?.product?.slug}`}
                      color="black"
                    >
                      {item?.product?.productName ?? ""}
                    </Typography>
                    <Box display="flex">
                      {item?.product?.selectedAttributes?.map(
                        (attribute, index) => (
                          <Typography variant="body1" key={index} mr={1}>
                            <span
                              style={{ fontWeight: "bold" }}
                            >{`${attribute?.name}: `}</span>
                            {`${attribute?.value}`}
                          </Typography>
                        )
                      )}
                    </Box>
                  </Box>
                  {item?.product?.sku && (
                    <Typography variant="body1" minWidth="125px">
                      <span style={{ fontWeight: "bold" }}>SKU:</span>
                      {` ${item?.product?.sku}${
                        item?.product?.partnerSku ?? ""
                      }`}
                    </Typography>
                  )}
                  <Typography variant="body1" minWidth="125px">
                    <span style={{ fontWeight: "bold" }}>Quantity:</span>
                    {` ${item?.quantity}`}
                  </Typography>
                  <CurrencyFormatter
                    variant="body1"
                    minWidth="125px"
                    fontWeight="bold"
                    number={item?.product?.price ?? 0}
                  />
                </Stack>
              ))}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
}

export default memo(PackagesSection);
