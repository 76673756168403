import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import parseEditorValue from "utils/helpers/parseEditorValue";
import http from "utils/httpRequest/http";

export const getTranslatedData = createAsyncThunk(
  "languageTranslation",
  async (data, { rejectWithValue }) => {
    const { text, lang } = data;
    const payload = {
      lang,
      text: {
        ...text,
        description: parseEditorValue(text.description) || "",
      },
    };
    try {
      const response = await http.post(`/translation`, payload);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

const languageTranslatorSlice = createSlice({
  name: "languageTranslation",
  initialState: {
    loading: true,
    translatedData: null,
    error: "",
  },
  reducers: {
    resetLanguageState: (state) => {
      state.loading = false;
      state.translatedData = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTranslatedData.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getTranslatedData.fulfilled, (state, action) => {
        state.translatedData = action.payload;
        state.loading = false;
      })
      .addCase(getTranslatedData.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetLanguageState } = languageTranslatorSlice.actions;

// Exporting default reducer
export default languageTranslatorSlice.reducer;
