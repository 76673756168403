import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import OrderDetails from "components/FullOrders/OrderDetails";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { getFullOrder, resetOrderState } from "store/slices/fullOrdersSlice";

function OrderDetailsPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const loading = useSelector((state) => state.fullOrders.orderLoading);
  const order = useSelector((state) => state.fullOrders.order);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const marginTopBottom = "15px";
  const marginLeftRight = "30px";
  const pageMargins = useMemo(() => {
    return `@page { margin: ${marginTopBottom} ${marginLeftRight} !important; }`;
  }, []);

  useEffect(() => {
    if (params?.id) {
      dispatch(getFullOrder({ orderId: params?.id }));
      return () => {
        dispatch(resetOrderState());
      };
    }
  }, [dispatch, params?.id]);

  if (!loading && !order)
    return (
      <Box mt={2}>
        <Typography
          variant="h6"
          component="h2"
          textAlign="center"
          color="text.secondary"
        >
          No order found !
        </Typography>
      </Box>
    );

  return (
    <Stack direction="column" gap={2} py={2} px={1}>
      <style>{pageMargins}</style>
      <OrderDetails ref={componentRef} />
      {!loading && (
        <Box textAlign="right">
          <Button
            variant="contained"
            onClick={handlePrint}
            size="large"
            sx={{ minWidth: { xs: "auto", md: "150px" } }}
          >
            Print
          </Button>
        </Box>
      )}
    </Stack>
  );
}

export default OrderDetailsPage;
