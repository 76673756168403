import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import styled from "@mui/material/styles/styled";
import DashboardAnalytics from "components/Dashboard/Analytics";
import KeyNumbers from "components/Dashboard/KeyNumbers";
import LineChart from "components/Dashboard/LineChart";
import SessionByDevice from "components/Dashboard/SessionByDevice";
import SpeedoMeter from "components/Dashboard/SpeedoMeter";
import StatsCard from "components/Dashboard/StatsCard";
import TopSearchedKeywords from "components/Dashboard/TopSearchedKeywords";
import UsersCard from "components/Dashboard/UsersCard";
import DateRange from "components/Pickers/DateRange";
import MonthPicker from "components/Pickers/MonthPicker";
import dayjs from "dayjs";
import PageTitle from "layout/PageTitle";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getMonthlyAnalytics } from "store/slices/dashboardSlice";
import USER_ROLE from "utils/constants/userRole";
import getTargetValue from "utils/helpers/getTargetValue";
import useQueryParams from "utils/hooks/useQueryParams";
import SalesFunnelBarChart from "../SalesFunnelBarChart";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}));

export default function AdminDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQueryParams();
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const userRole = useSelector((state) => state.auth?.userRole);
  const monthlyAnalytics = useSelector(
    (state) => state.dashboard.monthlyAnalytics.data
  );
  const chartLoading = useSelector(
    (state) => state.dashboard.ordersChart.loading
  );
  const keywordsLoading = useSelector(
    (state) => state.dashboard.topSearchedKeywords.loading
  );
  const insightsLoading = useSelector(
    (state) => state.dashboard.insights.loading
  );
  const monthlyAnalyticsLoading = useSelector(
    (state) => state.dashboard.monthlyAnalytics.loading
  );
  const revenueLoading = useSelector(
    (state) => state.dashboard.revenue.loading
  );

  const handleChange = useCallback(
    (range) => {
      let _params = params;
      _params.from = dayjs(range.startDate).format("YYYY-MM-DD");
      _params.to = dayjs(range.endDate).format("YYYY-MM-DD");
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  useEffect(() => {
    if ([USER_ROLE.ADMIN, USER_ROLE.SUPPLIER].includes(userRole)) {
      dispatch(
        getMonthlyAnalytics(
          params?.month
            ? dayjs(params?.month).startOf("month").format("YYYY-MM-DD")
            : dayjs().format("YYYY-MM-DD")
        )
      );
    }
  }, [dispatch, params?.month, userRole]);

  return (
    <Box py={4} display="flex" flexDirection="column" gap={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PageTitle
          title="Dashboard"
          loading={
            chartLoading || insightsLoading || keywordsLoading || revenueLoading
          }
        />
        <DateRange onChange={handleChange} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardAnalytics />
        </Grid>
        <Grid item xs={12} xl={isOpen ? 12 : 6} xxl={6}>
          <SalesFunnelBarChart />
        </Grid>
        <Grid item xs={12} xl={isOpen ? 12 : 6} xxl={6}>
          <Item>
            <LineChart />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <TopSearchedKeywords />
        </Grid>
      </Grid>

      <Divider variant="middle" sx={{ my: 2 }} />

      <PageTitle variant="h2" title="Platform Overview" />

      <Item>
        <KeyNumbers />
      </Item>

      <Divider variant="middle" sx={{ my: 2 }} />

      <Box display="flex" justifyContent="space-between" gap={2}>
        <PageTitle
          variant="h2"
          title="Monthly Statistics"
          loading={monthlyAnalyticsLoading}
        />
        <MonthPicker />
      </Box>

      <Grid container spacing={2}>
        <Grid item xl={3} sm={6} md={6} lg={3}>
          <StatsCard
            title={"Orders"}
            currentMonth={monthlyAnalytics?.orders?.currMonthTotalOrders}
            target={getTargetValue(
              monthlyAnalytics?.orders?.currMonthTotalOrders,
              1.5
            )}
            lastMonth={monthlyAnalytics?.orders?.prevMonthTotalOrders}
          />
        </Grid>
        <Grid item xl={3} sm={6} md={6} lg={3}>
          <StatsCard
            title={"Sales"}
            currentMonth={monthlyAnalytics?.orders?.currMonthSales}
            target={getTargetValue(
              monthlyAnalytics?.orders?.currMonthSales,
              1.5
            )}
            lastMonth={monthlyAnalytics?.orders?.prevMonthSales}
          />
        </Grid>
        <Grid item xl={3} sm={6} md={6} lg={3}>
          <StatsCard
            title={"Products"}
            currentMonth={monthlyAnalytics?.products?.currMonthProducts}
            target={getTargetValue(
              monthlyAnalytics?.products?.currMonthProducts,
              1.5
            )}
            lastMonth={monthlyAnalytics?.products?.prevMonthProducts}
          />
        </Grid>
        <Grid item xl={3} sm={6} md={6} lg={3}>
          <StatsCard
            title={"Videos"}
            currentMonth={monthlyAnalytics?.videos?.currMonthVideos}
            target={getTargetValue(
              monthlyAnalytics?.videos?.currMonthVideos,
              1.5
            )}
            lastMonth={monthlyAnalytics?.videos?.prevMonthVideos}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={isOpen ? 12 : 6} xxl={4}>
          <Item>
            <SessionByDevice />
          </Item>
        </Grid>
        <Grid item xs={12} lg={isOpen ? 12 : 6} xxl={4}>
          <Item>
            <UsersCard />
          </Item>
        </Grid>
        <Grid item xs={12} lg={isOpen ? 12 : 6} xxl={4}>
          <Item>
            <SpeedoMeter />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
