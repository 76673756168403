import parse from "html-react-parser";

const parseEditorValue = (value) => {
  const cleanValue = value?.replace(/\\n/g, "\n") ?? "";
  return String(cleanValue).includes("&lt;")
    ? parse(cleanValue).toString()
    : cleanValue;
};

export default parseEditorValue;
