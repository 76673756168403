import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import WritingIcon from "assets/icons/WritingIcon.svg";
import AliExpress from "assets/icons/aliexpress_icon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import Shopify from "assets/icons/shopify icon.svg";
import ChinaFlag from "assets/images/china-icon.png";
import productPlaceholder from "assets/images/imagePlaceholder.png";
import KsaFlag from "assets/images/ksa-icon.png";
import PakistanFlag from "assets/images/pakistan-icon.png";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledGrid from "components/StyledGrid";
import StyledSwitch from "components/ToggleButton";
import dayjs from "dayjs";
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addFeaturedProduct,
  deleteBulkProducts,
  deleteProduct,
  getAdminProducts,
  removeFeaturedProduct,
  updateBulkProducts,
} from "store/slices/productsSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import { ACTIVE_COLOR, INACTIVE_COLOR } from "styles/colors";
import USER_ROLE from "utils/constants/userRole";
import useQueryParams from "utils/hooks/useQueryParams";
import { toast } from "utils/hooks/useToast";
import EditableCell from "./EditableFields";

const BulkCategoryUpdateDialog = lazy(() =>
  import("./BulkCategoryUpdateDialog")
);

export default function ProductDataGrid() {
  const [openDialogue, setOpenDialogue] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const params = useQueryParams();
  const navigate = useNavigate();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const [selectedRows, setSelectedRows] = useState([]);
  const [action, setAction] = useState();
  const [isUpdateCategoryDialogOpen, setIsUpdateCategoryDialogOpen] =
    useState(false);
  const userRole = useSelector((state) => state.auth?.userRole);
  const { results, loading, totalPages } = useSelector(
    (state) => state.products
  );

  const mapActionToData = (actionId) => {
    switch (actionId) {
      case "active":
        return { active: true };
      case "inActive":
        return { active: false };
      case "featured":
        return { featured: true };
      case "unfeatured":
        return { featured: false };
      case "delete":
        return {};
      default:
        return {};
    }
  };

  const getOriginFlag = (origin) => {
    switch (origin) {
      case "ksa":
        return KsaFlag;
      case "pak":
        return PakistanFlag;
      case "china":
        return ChinaFlag;
      default:
        return null;
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogueClose = () => {
    setOpenDialogue(null);
  };

  const handleDialogueOpen = (event) => {
    const actionId = event.currentTarget.id;
    setAction(actionId);
    setOpenDialogue(true);
  };

  const handleBulkAction = useCallback(() => {
    const actionData = mapActionToData(action);
    const bulkData = {
      productsId: selectedRows,
      data: actionData,
    };
    const data = {
      productsId: selectedRows,
    };
    setOpenDialogue(false);

    if (action === "delete") {
      dispatch(deleteBulkProducts(data))
        .unwrap()
        .then(() => {
          toast.success("Selected Products deleted successfully");
          dispatch(getAdminProducts());
        });
    } else {
      dispatch(updateBulkProducts(bulkData))
        .unwrap()
        .then(() => {
          toast.success(`Selected Products ${action} successfully`);
          dispatch(getAdminProducts());
        });
    }
  }, [action, dispatch, selectedRows]);

  const handleSelectionModelChange = useCallback((newSelection) => {
    setSelectedRows(newSelection);
  }, []);

  const fetchData = useCallback(() => {
    const _params = { ...params };
    if (_params?.to) {
      if (dayjs(_params.to).startOf("day").isAfter(dayjs().startOf("day")))
        _params.to = dayjs().format("YYYY-MM-DD");
    }

    dispatch(getAdminProducts(_params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchData();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchData]);

  const handleChange = useCallback(
    (row) => {
      if (row.featured === false) {
        dispatch(
          addFeaturedProduct({
            id: row.id,
          })
        )
          .unwrap()
          .then(() => fetchData());
      } else {
        dispatch(
          removeFeaturedProduct({
            id: row.id,
          })
        )
          .unwrap()
          .then(() => fetchData());
      }
    },
    [dispatch, fetchData]
  );

  const handleBulkCategoryUpdate = useCallback((value = true) => {
    setIsUpdateCategoryDialogOpen(value);
  }, []);

  const handleClickOpen = useCallback(
    (productId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(productId));
    },
    [dispatch]
  );

  const UpdateProductRow = useCallback(
    (productId) => {
      navigate(`/products/update/${productId}`);
    },
    [navigate]
  );

  const basicColumns = useMemo(() => {
    const columns = [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 90,
        flex: 0.3,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Box display="flex" gap={1}>
            <Typography>{params?.row?.serialNumber}</Typography>
            {params?.row?.origin && (
              <img
                src={getOriginFlag(params?.row?.origin)}
                alt="Product Origin Flag"
                width={30}
              />
            )}
          </Box>
        ),
      },
      {
        field: "image",
        headerName: "Image",
        headerAlign: "center",
        align: "center",
        minWidth: 125,
        flex: 0.8,
        sortable: false,
        renderCell: (params) => (
          <Box mx="auto">
            <img
              src={
                params?.row?.mainImage
                  ? params?.row?.mainImage
                  : productPlaceholder
              }
              height={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              width={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 6,
                objectFit: "contain",
              }}
              alt="product_image"
            />
          </Box>
        ),
      },
      {
        field: "productName",
        headerName: "Name/Details",
        minWidth: 200,
        flex: 2,
        renderCell: (params) => (
          <Box>
            <Tooltip
              title={
                <p style={{ margin: 0, fontSize: "14px" }}>
                  {params?.row?.productName}
                </p>
              }
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  color: "#000000",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  // Additional lines for 2 line or multiline ellipsis
                  display: "-webkit-box !important",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  whiteSpace: "normal",
                }}
              >
                {params?.row?.productName}
              </Typography>
            </Tooltip>
            <Box display="flex" alignItems="baseline">
              <Typography
                sx={{
                  height: "12px",
                  width: "12px",
                  margin: "5px 6px 3px 0",
                  backgroundColor: params?.row?.active
                    ? ACTIVE_COLOR
                    : INACTIVE_COLOR,
                  borderRadius: "20px",
                }}
              />
              <Typography
                sx={{
                  color: params?.row?.active ? ACTIVE_COLOR : INACTIVE_COLOR,
                }}
              >
                {params?.row?.active ? "Active" : "Inactive"}
              </Typography>
            </Box>
            <Box display="flex" alignItems="baseline">
              Vendor:
              <Typography sx={{ color: "primary.main", marginLeft: 0.5 }}>
                {params?.row?.user?.sellerDetail
                  ? params?.row.user?.sellerDetail?.brandName
                  : params?.row?.user?.fullName}
              </Typography>
            </Box>
            <Box display="flex" alignItems="baseline">
              Category:
              <Typography sx={{ color: "primary.main", marginLeft: 0.5 }}>
                {params.row.categoryObj && params?.row?.categoryObj[0]?.name
                  ? params?.row?.categoryObj[0]?.name
                  : params?.row?.categoryObj?.name}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "quantity",
        headerName: "Qty/Options",
        minWidth: 90,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <EditableCell type="quantity" params={params} />
            <Typography>Options: {params?.row?.variants}</Typography>
          </Box>
        ),
      },
      {
        field: "price",
        headerName: "Price",
        minWidth: 90,
        flex: 1,
        renderCell: (params) => <EditableCell type="price" params={params} />,
      },
      {
        field: "platform",
        headerName: "Platform",
        headerAlign: "center",
        align: "center",
        minWidth: 90,
        flex: 1,
        renderCell: (params) => (
          <Box display="flex" alignItems="center" gap={0.5}>
            {params.row.platform === "shopify" && (
              <img src={Shopify} alt="Shopify" width={20} />
            )}
            {params.row.platform === "bazaarGhar" && (
              <img src="/favicon.ico" alt="MySouq" width={20} />
            )}
            {params.row.platform === "aliExpress" && (
              <img src={AliExpress} alt="AliExpress" width={20} />
            )}
            {params.row.platform === "ginkgo" && (
              <img
                src="https://ginkgoretail.com/wp-content/uploads/2021/09/favicon.png"
                alt="Ginkgo"
                width={20}
              />
            )}
            <Typography textTransform="capitalize">
              {params.row.platform === "bazaarGhar"
                ? "MySouq"
                : params.row.platform}
            </Typography>
          </Box>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        align: "center",
        minWidth: 130,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Box display="flex">
            <Tooltip title="Edit product" arrow placement="top">
              <IconButton
                size="small"
                onClick={() => UpdateProductRow(params.id)}
              >
                <img src={WritingIcon} alt="edit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete product" arrow placement="top">
              <IconButton
                size="small"
                onClick={() => handleClickOpen(params?.id)}
              >
                <img src={deleteIcon} alt="delete" />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ];

    if (userRole === USER_ROLE.ADMIN) {
      columns.push({
        field: "featured",
        headerName: "Featured",
        headerAlign: "center",
        align: "center",
        minWidth: 90,
        flex: 1,
        renderCell: (params) => (
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <StyledSwitch
                checked={params?.row?.featured}
                onChange={() => handleChange(params.row)}
              />
            }
          />
        ),
      });
    }

    return columns;
  }, [
    isTablet,
    isLaptop,
    isLed,
    userRole,
    handleChange,
    UpdateProductRow,
    handleClickOpen,
  ]);

  const columns = useMemo(() => {
    if (selectedRows.length > 1) {
      return [
        ...basicColumns,
        {
          field: "bulkActions",
          headerName: (
            <IconButton
              onClick={handleMenuClick}
              aria-controls={open ? "bulk-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              size="small"
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          ),
          headerAlign: "left",
          align: "left",
          minWidth: 60,
          flex: 0.25,
          sortable: false,
        },
      ];
    }
    return basicColumns;
  }, [basicColumns, open, selectedRows.length]);

  return (
    <>
      <StyledGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
        onSelectionModelChange={handleSelectionModelChange}
      />
      <ConfirmDelete deleteAction={deleteProduct} />
      <Menu
        anchorEl={anchorEl}
        id="bulk-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem id="active" onClick={handleDialogueOpen}>
          Active
        </MenuItem>
        <MenuItem id="inActive" onClick={handleDialogueOpen}>
          Inactive
        </MenuItem>
        <MenuItem id="category" onClick={handleBulkCategoryUpdate}>
          Category
        </MenuItem>
        {userRole === USER_ROLE.ADMIN && (
          <MenuItem id="featured" onClick={handleDialogueOpen}>
            Featured
          </MenuItem>
        )}
        {userRole === USER_ROLE.ADMIN && (
          <MenuItem id="unfeatured" onClick={handleDialogueOpen}>
            Unfeatured
          </MenuItem>
        )}
        <MenuItem id="delete" onClick={handleDialogueOpen}>
          Delete
        </MenuItem>
      </Menu>

      <Dialog
        open={openDialogue}
        onClose={handleDialogueClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to ${action} all products?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDialogueClose}>
            No
          </Button>
          <Button onClick={handleBulkAction} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {isUpdateCategoryDialogOpen && (
        <Suspense>
          <BulkCategoryUpdateDialog
            isOpen={isUpdateCategoryDialogOpen}
            handleDialogState={handleBulkCategoryUpdate}
            selectedRows={selectedRows}
          />
        </Suspense>
      )}
    </>
  );
}
