import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import StyledGrid from "components/StyledGrid";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getOrders } from "store/slices/ordersSlice";
import { getCancelOrders } from "store/slices/reportSlice";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";

function OrdersDataGrid({ value }) {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const { results, totalPages } = useSelector((state) => state.orders);
  const { cancelOrders } = useSelector((state) => state.reports);
  const cancelTotalPages = useSelector((state) => state.reports.totalPages);
  const orderLoading = useSelector((state) => state.orders.loading);
  const reportLoading = useSelector((state) => state.reports.loading);

  const get_orders = useCallback(() => {
    value === "CANCELORDERS"
      ? dispatch(getCancelOrders(params))
      : dispatch(getOrders(params));
  }, [dispatch, params, value]);

  useEffect(() => {
    if (value) {
      get_orders();
    }
  }, [dispatch, get_orders, value]);

  const columns = useMemo(
    () => [
      {
        field: "orderId",
        headerName: "OrderID",
        minWidth: 200,
        flex: 1.25,
        renderCell: (params) => (
          <Link
            to={`/orders/${params?.row?.id}`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              maxWidth: "100%",
            }}
          >
            <Typography sx={{ paddingY: "15px" }}>
              {params?.row?.orderId}
            </Typography>
          </Link>
        ),
      },
      {
        field: "fullname",
        headerName: "Customer Name",
        minWidth: 200,
        flex: 1.25,
        sortable: false,
        renderCell: (params) => (
          <Typography
            sx={{
              textTransform: "capitalize",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params?.row?.customer?.fullname}
          </Typography>
        ),
      },
      {
        field: "payable",
        headerName: "Payable",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <CurrencyFormatter
            number={params?.row?.total ?? 0}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          />
        ),
      },
      {
        field: "createdAt",
        headerName: "Order Date",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Typography>
            {dayjs(params?.row?.createdAt).format("DD/MM/YYYY")}
          </Typography>
        ),
      },
      {
        field: "cancelDate",
        headerName: "Cancel Date",
        minWidth: 100,
        flex: 1,
        hide: value !== "CANCELORDERS",
        renderCell: (params) => {
          const cancelStatus = params?.row?.orderStatuses?.find(
            (row) => row.status === "cancel"
          );
          return (
            <Typography>
              {dayjs(cancelStatus?.date ?? new Date()).format("DD/MM/YYYY")}
            </Typography>
          );
        },
      },
      {
        field: "name",
        headerName: "Status",
        type: "actions",
        minWidth: 125,
        flex: 0.8,
        sortable: false,
        renderCell: (params) => (
          <Chip
            sx={{
              backgroundColor: statusBadges(params?.row?.orderStatus?.name)
                .bgColor,
              color: statusBadges(params?.row?.orderStatus?.name).color,
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
              fontSize: "12px",
            }}
            variant="contained"
            label={params?.row?.orderStatus?.name}
          />
        ),
      },
    ],
    [value]
  );

  return (
    <StyledGrid
      rows={value === "CANCELORDERS" ? cancelOrders : results}
      columns={columns}
      loading={value === "CANCELORDERS" ? reportLoading : orderLoading}
      totalPages={value === "CANCELORDERS" ? cancelTotalPages : totalPages}
      checkboxSelection={false}
    />
  );
}

export default OrdersDataGrid;

OrdersDataGrid.propTypes = {
  value: PropTypes.string.isRequired,
};
