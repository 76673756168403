import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  Box,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { PRIMARY } from "styles/colors";

const iconMap = {
  new: <HourglassEmptyIcon />,
  confirmed: <CheckCircleIcon />,
  ready: <LocalShippingIcon />,
  delivered: <DoneAllIcon />,
  shipped: <LocalPostOfficeIcon />,
  completed: <CheckIcon />,
};

const ORDER_STATUS = [
  "new",
  "confirmed",
  "ready",
  "shipped",
  "delivered",
  "completed",
];

// Custom connector styling
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: PRIMARY,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: PRIMARY,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

function OrderTrackingDetail({ order, onClose }) {
  const activeStepIndex = ORDER_STATUS.findIndex(
    (status) => status === order?.orderStatus?.name
  );
  const trackingStepIndex = order?.orderTrack?.length - 1 || 0;

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      gap={2}
      sx={{
        pr: "1rem",
        pl: "1rem",
        py: "1rem",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        gap={2}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography
            variant="h2"
            fontWeight="bold"
            fontSize={{ xs: "1rem", sm: "1.25rem", md: "1.5rem" }}
            pt={1}
          >
            Tracking ID:{" "}
            <strong>{order?.consignment_no?.[0]?.consignment_no}</strong>
          </Typography>

          <Typography
            variant="h2"
            fontWeight="bold"
            fontSize={{ xs: "0.9rem", sm: "1.1rem", md: "1.25rem" }}
            color="text.disabled"
          >
            Order ID: <strong>{order?.orderId}</strong>
          </Typography>
        </Box>

        <IconButton
          aria-label="close"
          onClick={onClose}
          color="primary"
          sx={{
            color: "#fff",
            backgroundColor: "#058dce !important",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {order?.orderStatus?.name === "cancel" ? (
        <Box textAlign="center" py={4}>
          <Typography
            variant="h6"
            color="error"
            sx={{
              fontSize: { xs: "0.85rem", sm: "1rem", md: "1.25rem" },
              fontWeight: 500,
            }}
          >
            This order has been canceled
          </Typography>
        </Box>
      ) : (
        <Stepper
          alternativeLabel
          activeStep={activeStepIndex}
          connector={<CustomConnector />}
          sx={{
            p: { xs: 1, md: 4 },
            // overflow: isMobile ? "auto" : "none",
            "&::-webkit-scrollbar": {
              width: "6px",
              height: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "primary.main",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "primary.main",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              borderRadius: "10px",
            },
          }}
        >
          {ORDER_STATUS.map((status, index) => (
            <Step key={index}>
              <StepLabel
                sx={{}}
                StepIconComponent={() => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color:
                        index < activeStepIndex ||
                        order?.orderStatus?.name === "completed"
                          ? "primary.main"
                          : index === activeStepIndex
                          ? "primary.main"
                          : "text.secondary",
                    }}
                  >
                    {iconMap[status] || <CheckCircleIcon />}
                  </Box>
                )}
              >
                <Typography
                  variant="subtitle2"
                  fontSize={{
                    xs: "0.625rem",
                    sm: "0.725rem",
                    md: "1rem",
                  }}
                  fontWeight="500"
                  color={
                    index < activeStepIndex ||
                    order?.orderStatus?.name === "completed"
                      ? "primary.main"
                      : index === activeStepIndex
                      ? "primary.main"
                      : "text.secondary"
                  }
                >
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {order?.orderTrack && (
        <Stepper
          activeStep={0}
          orientation="vertical"
          connector={null}
          sx={{ ml: 2, maxHeight: "350px" }}
        >
          {order?.orderTrack.map((track, index, arr) => {
            const reverseIndex = arr.length - 1 - index;
            const isActive = reverseIndex === trackingStepIndex;

            return (
              <Step key={reverseIndex}>
                <StepLabel
                  StepIconComponent={() => (
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        borderRadius: "50%",
                        backgroundColor: isActive ? "primary.main" : "grey.400",
                        border: "2px solid",
                        borderColor: isActive ? "primary.main" : "grey.300",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "background-color 0.3s ease",
                      }}
                    >
                      {isActive && (
                        <Box
                          sx={{
                            width: 8,
                            height: 8,
                            backgroundColor: "white",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </Box>
                  )}
                >
                  <Box sx={{ ml: 2, position: "relative" }}>
                    <Typography
                      variant="body1"
                      fontWeight={isActive ? "bold" : "normal"}
                      color={isActive ? "primary.main" : "grey.400"}
                      sx={{
                        textTransform: "capitalize",
                        fontSize: { xs: "0.85rem", sm: "1.125rem" },
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textDecoration: "none",
                      }}
                    >
                      {arr[reverseIndex].status}
                    </Typography>

                    <Typography
                      variant="caption"
                      color="text.secondary"
                      fontWeight={isActive ? "bold" : "normal"}
                      sx={{
                        fontSize: { xs: "0.85rem", sm: "0.9rem" },
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textDecoration: "none",
                      }}
                    >
                      {dayjs(arr[reverseIndex].date).format(
                        "DD MMM, YYYY [at] hh:mm A"
                      )}
                    </Typography>
                  </Box>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
    </Stack>
  );
}

export default OrderTrackingDetail;

OrderTrackingDetail.propTypes = {
  order: PropTypes.object,
  onClose: PropTypes.func,
};
