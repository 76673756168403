import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTER_TEXT_COLOR, FILTERS_BACKGROUND_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const TRANSACTION_TYPES = [
  { label: "Credit", value: "credit" },
  { label: "Debit", value: "debit" },
];

function TransactionMethodFilter() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const [selectedTransactionType, setSelectedTransactionType] = useState(null);

  const handleChange = useCallback(
    (_, newValue) => {
      let _params = { ...params };

      if (newValue?.value) _params.type = newValue.value;
      else if (!newValue && _params?.type) {
        delete _params.type;
      }
      setSelectedTransactionType(newValue);
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  useEffect(() => {
    if (params?.method)
      setSelectedTransactionType(
        TRANSACTION_TYPES.find((type) => type.value === params.method) ?? null
      );
  }, [params]);

  return (
    <Autocomplete
      sx={{ minWidth: 170, maxWidth: 180 }}
      size="small"
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={TRANSACTION_TYPES}
      value={selectedTransactionType}
      noOptionsText="No Options Available"
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Transaction Type"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: "1.063rem",
              height: 44,
            },
            "& .MuiInputBase-root": {
              height: 44,
              backgroundColor: FILTERS_BACKGROUND_COLOR,
              cursor: "pointer",
            },
            input: {
              color: FILTER_TEXT_COLOR,
              "&::placeholder": {
                opacity: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default TransactionMethodFilter;
