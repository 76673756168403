import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { Formik } from "formik";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getReturnedProductById,
  updateOrderStatusAdmin,
} from "store/slices/returnsSlice";
import ReturnStatusOptions, {
  refundReasons,
  replacementReasons,
} from "utils/constants/ReturnStatusOptions";
import USER_ROLE from "utils/constants/userRole";
import * as Yup from "yup";

function UpdateReturnNote() {
  const dispatch = useDispatch();
  const params = useParams();
  const status = useSelector(
    (state) => state.returns.refundData?.refundStatus ?? ""
  );
  const userRole = useSelector((state) => state.auth?.userRole);
  const refundData = useSelector((state) => state.returns.refundData ?? null);
  const isUpdating = useSelector((state) => state.returns.isUpdating);
  const initialValues = useMemo(
    () => ({
      refundNote:
        refundData?.adminRefundNote ?? refundData?.sellerRefundNote ?? "",
      refundStatus: status ?? "",
    }),
    [refundData, status]
  );

  const isAdmin = userRole === USER_ROLE.ADMIN;
  const isRejectedByAdmin = refundData?.rejectByAdmin;
  const returnNote = refundData?.refundReason;

  // Function to determine if an option should be disabled
  const isStatusEnabled = (optionValue) => {
    // If rejected by Admin, no further actions allowed
    if (isRejectedByAdmin) {
      return false;
    }

    // If rejected by Supplier and user is not Admin, no further actions allowed
    if (status === "rejected" && !isAdmin) {
      return false;
    }

    switch (status) {
      case "requested":
        // Only approve and reject options are enabled for new requests
        return ["approved", "rejected"].includes(optionValue);

      case "rejected":
        // If admin, allow continuing the process
        return isAdmin ? ["approved"].includes(optionValue) : false;

      case "approved":
        // Only returned status is enabled
        return optionValue === "returned";

      case "returned":
        // Only received status is enabled
        return isAdmin ? false : ["received"].includes(optionValue);

      case "received":
        if (["refunded", "replacement"].includes(optionValue)) {
          if (refundReasons.includes(returnNote)) {
            // Only refund option is enabled
            return optionValue === "refunded";
          } else if (replacementReasons.includes(returnNote)) {
            // Admin can't change the status to replacement
            if (isAdmin) {
              return false;
            }
            // Only replacement option is enabled for supplier
            else return optionValue === "replacement";
          } else {
            // Both options are enabled if note doesn't match any condition
            return true;
          }
        }
        return false;

      default:
        return false;
    }
  };

  const handleSubmit = useCallback(
    (values) => {
      if (userRole === "admin") {
        const data = {
          adminRefundNote: values.refundNote,
          refundStatus: values.refundStatus,
          refundedAmount: refundData?.refundAmount,
        };
        dispatch(
          updateOrderStatusAdmin({ data, refundId: refundData?.id ?? "" })
        )
          .unwrap()
          .then(() => dispatch(getReturnedProductById(params.id)));
      } else {
        const _data = {
          sellerRefundNote: values.refundNote,
          refundStatus: values.refundStatus,
          refundedAmount: refundData?.refundAmount,
        };
        dispatch(
          updateOrderStatusAdmin({ _data, refundId: refundData?.id ?? "" })
        )
          .unwrap()
          .then(() => dispatch(getReturnedProductById(params.id)));
      }
    },
    [dispatch, params.id, refundData?.id, refundData?.refundAmount, userRole]
  );

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <StyledMuiCard
          title="Update Status and Note"
          subTitle="Please select a status and update note according to it"
        >
          <CardContent>
            <Stack
              direction="row"
              justifyContent="center"
              gap={3}
              p={{ xs: 1, md: 1.5 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Stack direction="column" gap={1}>
                    <Select
                      onChange={(e) => {
                        formik.setFieldValue("refundStatus", e.target.value);
                      }}
                      value={formik.values.refundStatus}
                      disabled={
                        isUpdating ||
                        isRejectedByAdmin ||
                        (!isAdmin && status === "rejected")
                      }
                    >
                      {ReturnStatusOptions.map((option) => (
                        <MenuItem
                          sx={{ fontSize: 16, fontWeight: "bold" }}
                          key={option.value}
                          dense
                          value={option.value}
                          disabled={!isStatusEnabled(option.value)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Stack direction="column" gap={1}>
                    <StyledTextField
                      multiline
                      rows={4}
                      type="text"
                      {...formik.getFieldProps("refundNote")}
                      disabled={
                        isUpdating ||
                        isRejectedByAdmin ||
                        (!isAdmin && status === "rejected")
                      }
                      error={Boolean(
                        formik.touched.refundNote && formik.errors.refundNote
                      )}
                      helperText={
                        formik.touched.refundNote && !!formik.errors.refundNote
                          ? formik.errors.refundNote
                          : "Please tell the customer about the return request"
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={12} textAlign="end">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disabled={
                      isUpdating ||
                      isRejectedByAdmin ||
                      (!isAdmin && status === "rejected")
                    }
                    sx={{
                      fontWeight: "bold",
                      width: 100,
                      height: 40,
                      textTransform: "none",
                    }}
                  >
                    {isUpdating ? "Updating..." : "Update"}
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </StyledMuiCard>
      )}
    </Formik>
  );
}

export default memo(UpdateReturnNote);

const validationSchema = Yup.object().shape({
  refundNote: Yup.string()
    .trim()
    .required("Required*")
    .min(40, "Note should not be less than 40 characters"),
  refundStatus: Yup.string().required("Required*"),
});
