import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

export const getReturns = createAsyncThunk(
  "returns/getReturns",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().groupBuy;
    try {
      const response = await http.get(`/refund/seller`, {
        params: {
          ...data,
          page: data.page ?? page,
          limit: data.limit ?? limit,
          sortBy: "-createdAt",
        },
      });
      const responseData = getResponseData(response);
      const results = responseData?.results || responseData?.result;
      if (results) {
        results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getReturnedProductById = createAsyncThunk(
  "returns/getReturnedProductById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await http.get(`/refund/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateOrderStatusAdmin = createAsyncThunk(
  "updateOrderStatusAdmin",
  async (values, { rejectWithValue }) => {
    try {
      const response = await http.patch(
        `/refund/seller/${values.refundId}`,
        values.data ?? values._data
      );
      const responseData = getResponseData(response);
      if (responseData?.isSuccess === true) {
        toast.success(responseData?.message);
      }
      if (responseData?.isSuccess === false) {
        toast.error(responseData?.message);
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getReturnedProduct = createAsyncThunk(
  "getReturnedProduct",
  async () => {}
);

export const updateReturnedProduct = createAsyncThunk(
  "updateReturnedProduct",
  async () => {}
);

const returnsSlice = createSlice({
  name: "returns",
  initialState: {
    loading: true,
    results: [],
    refundData: null,
    refundStatus: "",
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: "",
    isUpdating: false,
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.results = [];
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReturns.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getReturns.fulfilled, (state, action) => {
        state.results = action.payload.results || action.payload.result;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.loading = false;
      })
      .addCase(getReturns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getReturnedProductById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getReturnedProductById.fulfilled, (state, action) => {
        state.refundData = action.payload;
        state.refundStatus = action.payload?.refundStatus;
        state.loading = false;
      })
      .addCase(getReturnedProductById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(updateOrderStatusAdmin.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateOrderStatusAdmin.fulfilled, (state, action) => {
        state.isUpdating = false;
        state.refundData = action.payload?.data;
      })
      .addCase(updateOrderStatusAdmin.rejected, (state) => {
        state.isUpdating = false;
        state.error = true;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState } = returnsSlice.actions;

// Exporting default reducer
export default returnsSlice.reducer;
